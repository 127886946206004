import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Container,
  // Button
} from "react-bootstrap";
import {
  graphql,
  useStaticQuery,
  // Link
} from "gatsby";
import { mergePrismicPreviewData } from "gatsby-source-prismic";
import axios from "axios";

import { cartContext } from "providers/CartProvider";

import Layout from "components/Layout";
import SEO from "components/Seo";
import Banner from "components/Banner";
import IconBoxGrid from "components/IconBoxGrid/IconBoxGrid";
import Testimonials from "components/Testimonials/Testimonials";
import CtaButton from "components/CtaButton";
import { HTMLContent } from "components/Content";

const IndexPage = () => {
  const [marqueeData, setMarqueeData] = useState(null);
  const siteContext = useContext(cartContext);

  const data = useStaticQuery(graphql`
    query homePageQuery {
      prismicHomepage {
        data {
          intro_title {
            text
          }
          intro_text {
            html
          }
          intro_right_title {
            text
          }
          intro_right_text {
            html
          }
          class_info_title {
            text
          }
          class_information_text {
            html
          }
          testimonials {
            testimony_job
            testimony_body {
              html
            }
            testimony_name {
              text
            }
            testimony_photo {
              fixed(width: 100) {
                src
                srcSet
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.GATSBY_API_URL}/marquee`,
          {
            validateStatus(status) {
              return status < 500; // Resolve only if the status code is less than 500
            },
          }
        );

        // console.log("Main -> response.data", response.data);
        setMarqueeData(response.data);
      } catch (e) {
        siteContext.makeToast("Unable to fetch marquee data.", "error");
      }
    }

    fetchData();
  }, [siteContext]);

  const IS_BROWSER = typeof window !== "undefined";
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const staticData = data;

  const contentMerged = mergePrismicPreviewData({ staticData, previewData });
  const content = contentMerged.prismicHomepage.data;

  return (
    <Layout pageInfo={{ pageName: "index" }}>
      <SEO title="Home" />

      {marqueeData &&
        marqueeData.enabled &&
        marqueeData?.items.map((m, i) => (
          <div className="big-marquee" key={i}>
            <div className="container">
              <h5>
                <span style={{ textTransform: "uppercase" }}>{m.label}</span>{" "}
              </h5>
              {m.url && (
                <p className="pt-2 pb-2">
                  <a className="btn btn-danger" href={m.url}>
                    CLICK HERE
                  </a>
                </p>
              )}
            </div>
          </div>
        ))}

      <Banner isHome />

      <section>
        <Container>
          <Row>
            <Col xs="12" lg="6" className="mainIntro">
              <p className="mini-lead">Welcome</p>
              <h2>{content.intro_title.text}</h2>

              <HTMLContent
                content={content.intro_text.html}
                className="intro"
              />

              <CtaButton
                label="ENROLL NOW"
                className="mt-3"
                url="/class-schedules"
              />
            </Col>

            <Col xs="12" lg="6" className="mt-5 mt-lg-0">
              <p className="mini-lead">Why Us?</p>
              <h4>{content.intro_right_title.text}</h4>

              <HTMLContent content={content.intro_right_text.html} />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="dark">
        <Container>
          <Row>
            <Col xs="12" className="mx-auto">
              <div className="text-center">
                <p className="mini-lead">What We Offer</p>
                <h2>{content.class_info_title.text}</h2>

                <HTMLContent content={content.class_information_text.html} />

                <p className="lead font-weight-bold">
                  Click one of the training programs below to view schedules,
                  get pricing, and purchase enrollment.
                </p>
              </div>

              <IconBoxGrid />

              <div className="text-center mt-5">
                <p>
                  Other specialty classes offered which may not be on currently
                  posted schedules: Private Investigator, Personal Protections
                  Specialist, Expandable Baton, OC Pepper Spray, Defensive
                  Tactics, Handcuffing, First Aid/CPR/AED.
                </p>
                <p>
                  LE classes: Handgun/Shotgun, Patrol Rifle, Precision Rifle,
                  Tactical Shotgun, Monadnock Expandable Baton entry level,
                  Recert and Instructor.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bluedark">
        <Container>
          <Row>
            <Col xs="12" md="6" className="text-center text-md-left">
              <p className="mini-lead">What They Say</p>
              <h2>Client Testimonials</h2>

              <p className="lead">
                Don't just take our word for it. Check out what some of our past
                students say!
              </p>
            </Col>

            <Col xs="12" md="6" className="mt-3 mt-md-0">
              <Testimonials data={content.testimonials} />
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="10" className="mx-auto text-center">
              <p className="mini-lead">Get Started</p>
              <h2>Your Future in Security Starts Here</h2>
              <p className="lead">
                Enroll now to start your journey towards a better future.
              </p>

              <CtaButton
                label="View Classes"
                url="/class-schedules"
                className="mt-3"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default IndexPage;
