import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import Testimonial from "./Testimonial";

const Testimonials = ({ data }) => (
  <div className="testimonials-list d-flex flex-column justify-content-center">
    <Carousel dots draggable infinite autoPlay={8000}>
      {data.map((item, i) => (
        <Testimonial data={item} key={i} />
      ))}
    </Carousel>
  </div>
);

export default Testimonials;
