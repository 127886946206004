import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import IconBox from "./IconBox";

const IconBoxGrid = () => {
  const data = useStaticQuery(graphql`
    query {
      inServicePic: file(name: { eq: "inservice" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      ccwPic: file(name: { eq: "ccw" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allPic: file(name: { eq: "full_sched" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      careerPaths: allPrismicCareerpaths {
        nodes {
          data {
            careers {
              career_short_description {
                html
              }
              career_title {
                text
              }
              career_slug
              career_img {
                localFile {
                  childImageSharp {
                    fluid(quality: 75, maxWidth: 350) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { careers } = data.careerPaths.nodes[0].data;
  // console.log("careers", careers);

  return (
    <div className="d-flex flex-column flex-md-row flex-wrap justify-content-center align-items-center align-items-md-stretch  mt-5">
      {careers
        .filter(x => x.career_slug !== "requalifications")
        .map(career => (
          <IconBox
            key={career.career_slug}
            title={career.career_title.text}
            description={career.career_short_description.html}
            pic={career.career_img.localFile.childImageSharp.fluid}
            linksTo={`/class-schedules/${career.career_slug}`}
          />
        ))}

      <IconBox
        key="requals"
        title="Requalifications"
        description="<p>Firearms Requalification / LEOSA.</p>"
        pic={data.ccwPic.childImageSharp.fluid}
        linksTo="/class-schedules/requalifications/"
      />

      <IconBox
        key="in-service"
        title="In-service Renewals"
        description="<p>For in-service renewals, click here.</p>"
        pic={data.inServicePic.childImageSharp.fluid}
        linksTo="/inservice-renewals/"
      />

      <IconBox
        key="all"
        title="Full Schedule"
        description="<p>View our entire list of upcoming classes.</p>"
        pic={data.allPic.childImageSharp.fluid}
        linksTo="/class-schedules/all"
        customAriaLabel="Display list of all upcoming class dates and times."
      />
    </div>
  );
};

export default IconBoxGrid;
