import React from "react";
import styled from "styled-components";
import { IoMdArrowForward } from "react-icons/io/index.esm";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { HTMLContent } from "components/Content";

const StyledIconBox = styled.div`
  padding: 0;
  margin: 0.33rem;
  width: 100%;
  max-width: 350px;
  background: rgb(240, 240, 240);
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media screen and (min-width: 580px) {
    width: calc(390px - 0.66rem);
  }

  @media screen and (min-width: 768px) {
    width: calc(300px - 0.66rem);
  }

  div.ib-upper {
    width: 100%;
  }

  .ib-lower {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 1.5rem 0 1.5rem 1rem;
    background-color: rgb(240, 240, 240);
    background-image: linear-gradient(
      180deg,
      rgba(30, 30, 30, 0.2),
      rgba(240, 240, 240, 0) 10px
    );
    border-top: 1px solid #ccc;
    flex: 1;

    &:hover {
      text-decoration: none;
      background-image: linear-gradient(
        180deg,
        rgba(30, 30, 30, 0.4),
        rgba(240, 240, 240, 0) 10px
      );

      div.icon {
        background-color: #258df5;
      }
    }

    div.icon {
      background: #133165;
      border-left: none;
      margin: 0;
      overflow: hidden;
      padding: 0.5rem;
      color: #fff;
      text-align: center;
      flex-shrink: 0;
    }
  }

  div.content {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 1rem;
    color: #222;

    h3 {
      color: #222;
      font-size: 1.1rem;
      margin: 0 0 0.3rem;
      padding: 0;

      &::after {
        content: none;
      }
    }

    span {
      width: 40px;
      height: 1px;
      background: #999;
      margin: 0.5rem 0;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 0.9rem;
      line-height: 1.33;
    }
  }
`;

const IconBox = ({
  title = "Title",
  description = "Lorem ipsum dolor sit amet, elit spec unum.",
  linksTo = `/class-schedules/armed-security-officer`,
  pic = null,
  customAriaLabel = null,
}) => (
  <StyledIconBox>
    <div className="ib-upper">
      {pic && (
        <Link to={linksTo} aria-label={`${title} graphic`}>
          <Img fluid={pic} />
        </Link>
      )}
    </div>

    <Link
      className="ib-lower"
      to={linksTo}
      aria-label={customAriaLabel || `View ${title} details and schedules`}
      title={customAriaLabel || `View ${title} details`}
    >
      <div className="content">
        <h3>{title}</h3>
        <span />
        <HTMLContent content={description} className="shortdesc" />
      </div>

      <div className="icon">
        <IoMdArrowForward size="24px" />
      </div>
    </Link>
  </StyledIconBox>
);

export default IconBox;
