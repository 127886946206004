import React from "react";
import styled from "styled-components";
import { FaQuoteLeft } from "react-icons/fa/index.esm";

import { HTMLContent } from "components/Content";

const StyledTestimonial = styled.div`
  border: 1px solid rgba(240, 240, 240, 0.3);
  background: rgba(240, 240, 240, 0.2);
  box-shadow: 1px 1px 2px rgba(20, 20, 20, 0.3);
  display: flex;
  flex-direction: row;
  padding: 1rem;

  div.icon {
    align-self: flex-start;
    padding: 0.5rem;
    margin: 0 0.5rem;
    border: 1px solid #ccc;
    border-radius: 50%;
    line-height: 1;
  }

  div.avatar {
    width: 100px;
    height: 100px;
    background: rgba(190, 190, 190, 0.8);
    flex: 1 0 100px;
  }

  div.copy {
    font-style: italic;
  }

  div.author {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.95rem;
    letter-spacing: 0.02rem;

    &::before {
      content: "\\2014";
      display: block;
      font-weight: 800;
    }

    span {
      font-style: italic;
      font-weight: 400;
      text-transform: none;
      display: block;
    }
  }
`;

const Testimonial = ({ data }) => (
  <StyledTestimonial className="testimonial">
    <div className="avatar">
      <img
        srcSet={data.testimony_photo.fixed.srcSet}
        alt="testimonial"
        width="100"
      />
    </div>

    <div className="icon">
      <FaQuoteLeft />
    </div>

    <div className="content">
      <HTMLContent className="copy" content={data.testimony_body.html} />
      <div className="author">
        {data.testimony_name.text}{" "}
        {data.testimony_job && <span>({data.testimony_job})</span>}
      </div>
    </div>
  </StyledTestimonial>
);

export default Testimonial;
