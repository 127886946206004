import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import CtaButton from "./CtaButton";

const StyledHeader = styled.header`
  /* padding: ${props => (props.isHome ? "150px 0 100px" : "20px 0")}; */
  padding: 0;
  position:  relative;
  overflow: hidden;
  background: rgb(7, 16, 33);

  div.hero-div {
    /* display: flex; */
    position: relative;
    z-index: 1;
  }

  div.hero-pic {
    display: flex;
    height: 100%;
    min-height: 290px;

    @media screen and (min-width: 768px) {
      min-height: 380px;
    }

    div.gi-wrap {
      position: unset !important;
    }
  }

  div.hero-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;

    @media screen and (min-width: 576px) {
      align-items: flex-start;
    }

    h1 {
      font-size: 2rem;
      text-transform: uppercase;
      font-weight: bold;

      span {
        color: #73BDFF;
        display: block;
        /* background: rgba(220,220,250,0.03); */
        padding: 0;
        margin: 0.25rem 0;        
      }
    }

    p.banner-lead {
      font-size: 1.2rem;
      line-height: 1.66;
      border-top: 1px solid rgba(150,150,150,0.2);
      padding: 1rem 0 0;
      margin: 1rem 0 0;
      color: rgb(240,255,255);
      font-style: italic;
      text-align: center;

      @media screen and (min-width: 576px){
        text-align: left;
      }

      @media screen and (min-width: 992px) {
        max-width: 600px;
      }
    }
  }
`;

const Banner = ({ subtitle }, ...props) => {
  const data = useStaticQuery(graphql`
    query {
      heroPic: file(name: { eq: "range_bp_color" }) {
        childImageSharp {
          fluid(quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  if (!data.heroPic) {
    return null;
  }

  const subTitle =
    subtitle ||
    "Whether you are looking for a new career or career advancement, an investment in training pays the best Interest.";

  return (
    <StyledHeader className="text-white" {...props}>
      <Container>
        <Row>
          <Col xs="12" sm="7" md="6" lg="7" className="hero-text">
            <h1>
              Expert Training. <span>A Better Future.</span>
            </h1>

            <p className="banner-lead">{subTitle}</p>

            <CtaButton
              label="VIEW CLASS SCHEDULES"
              className="mt-4 mt-xl-5 my-xl-2 banner"
              url="/class-schedules"
            />
          </Col>

          <Col xs="12" sm="5" md="6" lg="5">
            <div className="hero-pic">
              <Img
                fluid={data.heroPic.childImageSharp.fluid}
                className="gi-wrap"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </StyledHeader>
  );
};

export default Banner;
